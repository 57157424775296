@import url('https://fonts.googleapis.com/css?family=Josefin+Slab:400,700');

$primary: #ee5b54;
/* MAIN COLOR */
$secondary: #3d97a8;
/* SECONDARY COLOR */
$gray: #666;
$blk: #222;
$wht: #f8f8f8;
$links: #444;
body {
  font-family: 'Josefin Slab', serif;
  color: $blk;
  font-size: 1.5em;
}
hr.style11 {
	height: 6px;
	background: url('../img/hr.png') repeat-x 0 0;
    border: 0;
}
.navbar {
  margin-bottom: 0;
}
.navbar-nav {
    margin: 0 -15px;}
.navbar, .navbar-header{
  height: 100px;
  // position: relative;

  .navbar-nav>li>a {
    margin-top: 25px;
    text-transform: uppercase;
    font-size: 1.2em;
    &:hover{
      border-top: 2px solid $primary;
      border-bottom: 2px solid $primary;
      color: $primary;
      border-radius: 0;
      transition: all 0.7s ease 0s;
      @media(max-width:767px){
        background-color: $wht;
        color: $blk;
        border-radius: 0;
        border:0px solid $wht;
      }
    }
    background: $wht;
    @media (max-width:767px){
      line-height: 20px;
      margin-top: 0;
    }
  }
  @media (max-width: 767px){
    height: 70px;
    .logo {
      max-height: 70px;
    }
  }
}
.navbar-brand {
    padding: 0px 15px;
}
.cta-box{
  padding: 20px 0px;
}
.cta {
  padding: 20px 30px;
  background: $primary;
  font-size: 1.2em;
  border-radius: 4px;
  color: $wht;
  transition: all 0.7s ease 0s;
  text-decoration: none;
  &:hover{
    background: $wht;
    color:$primary;
    text-decoration: none;
    box-shadow: 0px 0px 4px darken($wht, 25%);
  }
}
.hero-slider{
  padding: 80px 0;
  margin-top: 100px;
  background: lighten($secondary, 22%);
  @media(max-width:767px){
    margin-top: 70px;
    padding: 20px 0;
  }
  .img-right{
    img {
      box-shadow: 0px 0px 25px darken($primary, 0%);
    }
  }
  h2 {
    font-size: 2em;
  }
  p {
    font-size: 1.5em;
  }
}
.hero-services{
  box-shadow: 0px 0px 12px lighten($blk, 50%);

  background: lighten($secondary, 22%);
  color: $blk;
  padding: 50px 0 ;
  .second-serv{
    @media(max-width:991px){
      border-top: 1px solid $primary;

    }
  }
  h1 {
    padding: 0 0 25px 0;
    color: $primary;
    font-weight: bold;
    font-size: 3em;
  }
  .col-1, .col-2{
    // margin: 3px;
    padding: 25px 0;
    // border: 2px solid $primary;
  }
  p {
    font-size: 1.3em;
    padding: 0 20px;
  }
}
.hero-about {
  padding: 60px 0;
  background-color:lighten($wht, 2%);
  box-shadow: 0px 0px 12px lighten($blk, 50%);
  color: $blk;
  @media(max-width: 767px){
    padding: 20px 0;
  }
  h2 {
    color: $primary;
    font-weight: bold;
    font-size: 3em;
  }
  p {
    font-size: 1.4em;
    font-weight: 500;
    color: $blk;
    @media (max-width: 767px){
      margin: 20px 0;
      font-size: 1.2em;
    }
  }
}
.hero-img {
  background-image: url('../img/hero-img.jpg');
  box-shadow: 0px 0px 12px lighten($blk, 50%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  center;
  padding: 250px 0;
  .container{
    background: rgba(255, 255, 255, .9);
    padding: 30px 0;
    // box-shadow: 0px 0px 12px lighten($primary, 50%);

  }
  h1 {
    color: $blk;
    @media(max-width: 767px){
      font-size: 1.5em;
    }
  }
  @media(max-width:1024px){
    background-attachment: scroll;
    padding: 150px 0;
  }
  @media(max-width: 767px){
    padding: 100px 0;
  }
}
.hero-contact {
background: lighten($secondary, 22%);  padding: 0;
  color: $blk;
  .col-md-12 {
    padding: 20px 50px;
    @media(max-width: 767px){
      padding:40px 20px;
      color: lighten($blk, 20%);
    }
    ul {
      @media (max-width: 480px){
        padding-left: 10px;
      }
    }
    li {
      list-style: none;
      line-height: 26px;
    }
    a {
      text-decoration: none;
      color: lighten($blk, 5%);
      font-weight: 100;
    }
    p {
      font-size:1.2em;
      line-height:1.5em;
      color: lighten($wht, 5%);
    }
    .footer-banner {
      ul {
        padding-left: 0;
      }
    }
  }
}
footer {
  text-align: center;
  padding-top: 25px;
  background: $gray;
    color: lighten($wht, 20%);
}
